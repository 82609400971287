* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'SF UI Text', 'Roboto', sans-serif;
  background-color: #f8f8f8;
  color: #333;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
}

button {
  font-size: 1em;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

button:active {
  outline: 0;
}

input::placeholder {
  color: #999;
}

div.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker__close-icon::after {
  background: #ccc;
  margin-top: -23px;
  margin-right: -27px;
  cursor: pointer;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  line-height: 0.7em;
  padding: 4px;
  font-size: 21px;
  z-index: 1;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  z-index: 0;
}

.tippy-tooltip.no-padding-theme {
  padding: 0;
}

.tippy-tooltip.alert-theme {
  background-color: #ffd326;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.alert-theme .tippy-arrow {
  border-top-color: #ffd326;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.alert-theme .tippy-arrow {
  border-bottom-color: #ffd326;
}

.ril__captionContent {
  margin: auto;
}

.slider-slide {
  top: unset !important;
  bottom: 0px !important;
}

.video-editor-react-player video {
  max-height: 100%;
}

.snapshot-generator {
  display: block;
  height: 1px;
  left: 0;
  object-fit: contain;
  position: fixed;
  top: 0;
  width: 1px;
  z-index: -1;
}

.ql-container.ql-editor {
  min-height: 280px;
}

div.quill.ql-lifetales .ql-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
  font-size: 16px;
}

div.quill.ql-lifetales p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.quill.ql-lifetales h1,
.quill.ql-lifetales h2,
.quill.ql-lifetales h3,
.quill.ql-lifetales h4,
.quill.ql-lifetales h5,
.quill.ql-lifetales h6 {
  font-family: inherit;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.quill.ql-lifetales h1 { font-size: 2.5rem !important; }
.quill.ql-lifetales h2 { font-size: 2rem !important; }
.quill.ql-lifetales h3 { font-size: 1.75rem !important; }
.quill.ql-lifetales h4 { font-size: 1.5rem !important; }
.quill.ql-lifetales h5 { font-size: 1.2rem !important; }
.quill.ql-lifetales h6 { font-size: 1rem !important; }

div.quill.ql-lifetales ol,
div.quill.ql-lifetales ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

div.quill.ql-lifetales li { padding-bottom: .4em; }
div.quill.ql-lifetales li:last-child { padding-bottom: 0; }

.quill.ql-lifetales ol ol,
.quill.ql-lifetales ul ul,
.quill.ql-lifetales ol ul,
.quill.ql-lifetales ul ol {
  margin-bottom: 0;
}

.quill.ql-lifetales a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.quill.ql-lifetales a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.quill.ql-lifetales a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.quill.ql-lifetales a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

.ql-indent-1 {
  padding-left: 3em;
}
