.connection-avatar {
  position: relative;
  font-family: 'SF UI Text', sans-serif;
  background: white;
  color: #333;
  box-sizing: border-box;
  max-width: 180px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
}

.connection-avatar__button {
  font-family: 'SF UI Text', sans-serif;
  padding: 7px 12px;
  color: #7c69c0;
  border: 1px solid #7c69c0;
  border-radius: 100px;
  background: white;
  font-size: 14px;
  line-height: 1;
  margin-top: 5px;
  cursor: pointer;
}

.connection-avatar__button .fa {
  margin-right: 3px;
}

.connection-avatar:hover .connection-avatar__delete {
  display: block;
}

.connection-avatar__delete {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  border: none;
  background: none;
  cursor: pointer;
}

.connection-avatar__delete .fa {
  color: #999;
  font-size: 16px;
  line-height: 1;
}

.connection-avatar__name {
  line-height: 19px;
  margin: 9px 0 0;
  width: 100%;
  word-wrap: break-word;
}

.connection-avatar__status {
  font-size: 13px;
  line-height: 12px;
  color: #999;
}

.connection-avatar .member-avatar {
  height: 100px;
  width: 100px;
}
