.modal__wrapper {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
}

.modal__shade {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.modal {
  display: flex;
  flex-direction: column;
  background: #F7F7F7;;
  font-size: smaller;
  width: 88vw;
  max-width: 450px;
  max-height: 100vh;
  position: relative;
  z-index: 2;
  box-shadow: 0.5vw 0.5vw 0.5vw rgba(34, 34, 34, 0.5);
  border-radius: 0.6em;
  margin: auto;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}

.add-friend-button {
  margin-left: auto;
}

.modal__header {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding: 0 0 0.5em 1em;
  margin-top: 0.5em;
  min-height: 44px;
}

.modal__header-label {
  font-size: 24px;
  color: #666;
  text-align: center;
  width: 100%;
  padding-top: 0.25em;
}

.modal__header-close {
  background: none;
  border: none;
  color: #676767;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3em;
  font-family: Quicksand;
  padding-right: 0.6em;
  padding-top: 0px;
  text-decoration: none;
  margin-left: auto;
  z-index: 2;
}

.modal__header-close img {
  width: 1em;
}

.modal__header-close:hover {
  color: #7A69BF;
}

.modal__footer-close {
  color: #fff;
  text-decoration: none;
  padding: 0.5em 2.25em;
  border-radius: 0.25em;
  background: #7A69BF;
  font-size: 1.5em;
  cursor: pointer;
}

.modal__footer {
  text-align: center;
  padding: 0.5em 0.5em 1em;
  margin-top: -1.5em;
  font-size: smaller;
  padding: 1em 0 1.25em;
  margin-top: -2.9em;
  font-size: smaller;
}

.modal__body {
  width: 100%;
  margin: auto;
  margin-bottom: 1.5em;
  padding: 0 1.5em
}

.modal__on-close-button {
  background: none;
  border: none;
  width: auto;
  margin-left: auto;
}

@media screen and (max-width: 576px),
@media screen and (max-height: 576px) {
    .modal {
        font-size: smaller;
    }

    .modal__header {
        margin-top: 0;
    }

    .modal__header-close {
        font-size: 1em;
        top: 0.6em;
        position: relative;
    }

    .modal__footer {
        font-size: x-small;
    }

    .add-friend-title {
        display: none;
    }

    .add-friend__wrapper {
        padding: 9px 10px;
    }
}

@media screen and (max-height: 311px) {
    .modal__header {
        min-height: 2.5em;
    }

    .modal__header-label {
        display: none;
    }

    .modal__body {
        margin-bottom: 0;
    }

    .add-friend__empty .fa {
        display: none;
    }

    .modal__footer {
        display: none;
    }
}
